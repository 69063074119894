@import 'Variables'
@import 'node_modules/bootstrap/scss/bootstrap'
@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap')

@font-face 
  font-family: Pragmatica Extended
  src: url('../assets/fonts/PragmaticaExtendedBold.otf')


\:root
  --title-font-size: 2.7rem
  --padding-width: #{$padding-width}
  --max-content-width: calc(#{$max-content-width} + #{$padding-width} * 2)
  --scroll-padding: 1rem
  @media (max-width: $mid-bp)
    --padding-width: #{$padding-width / 2}


html
  font-size: #{$font-base}
  scroll-behavior: smooth !important
  scroll-padding-top: var(--scroll-padding, 96px) !important

  @media (min-width: 1200px) 
    font-size: #{$content-size * $maxVW}px
  
  @media (max-width: $mid-bp)
    font-size: calc(#{$content-size * $maxVW / 3}px + 1.2vw)
    

.btn
  padding: 0.5rem 1.5rem
  font-weight: bold
  margin: 0.5rem 0
  font-family: 'Poppins', 'Roboto', sans-serif
  font-size: 1.21rem
  min-width: 8rem
  border-radius: 0
  box-shadow: none !important
  height: var(--title-font-size)
  line-height: 1em
  &:is(a)
    line-height: 1.35

.my-margin
  padding-left: var(--padding-width) !important
  padding-right: var(--padding-width) !important
  max-width: var(--max-content-width)
  margin: 0 auto

hr
  opacity: 1
  height: 0.07rem

img
  width: 100%
  height: 100%

h1, h2, h3, h4, h5, button, a, .title
  font-family: Pragmatica Extended, Roboto
  text-transform: uppercase

.title .text-weight-light
  font-weight: 100

body
  --navbar-height: calc(96px + (100vw - 900px) / 13)
  @media (width < 900px) 
    --navbar-height: 96px
  @media (width > 1250px) 
    --navbar-height: 123px