
@font-face
  src: url('../../public/fonts/roboto-v20-latin-ext_latin-regular.ttf')
  font-family: 'Roboto'

@font-face
  src: url('../../public/fonts/RobotoMono.ttf')
  font-family: 'Roboto Mono'

$primary: #1a1a1a
$secondary: white
$light: $secondary
$dark: $primary
$body-bg: $secondary
$body-color: $primary
$font-family-base: 'Poppins'
$font-family-monospace: 'Roboto Mono'
$font-family-sans-serif: 'Poppins'
$max-content-width: 1020px
$title-size: 4.5
$content-size: 1.65
$maxVW: 12
$font-base: #{$content-size}vw
$padding-width: 4.5rem
$mid-bp: 650px
$sm-bp: 442px