@import '../Variables'

.new
    &-single
        &__content
            @media (min-width: 651px) 
                display: flex
                gap: 2rem
                justify-content: center
            
            width: 100%
            max-height: 100%
            &__side 
                min-width: 13.6em
                display: flex
                justify-content: space-around
                align-items: center
                font-weight: bold
                & > :first-child
                    margin-top: 0.5rem
                @media (min-width: 651px) 
                    flex-direction: column
                    align-items: flex-start
                    & > :first-child
                        margin-top: 0
            > div:first-child
                display: flex
                align-items: center
                img
                    width: auto
                    height: auto
                    max-height: 100%
                    max-width: 100%
                    object-fit: cover
    &-album-box
        display: grid
        gap: 2rem
        grid-template-columns: minmax(0, 3fr) minmax(0, 2fr)
        margin-bottom: 1rem
        &__image
            aspect-ratio: 1/1
            height: auto
        &__side
            display: flex
            flex-direction: column
            justify-content: center
        @media (width <= 500px)
            display: block
            &__side
                margin-top: 1rem
                flex-direction: row
                justify-content: space-between

.discography
    padding: var(--padding-width)
    &__list
        display: grid
        gap: 2rem
        grid-template-columns: 1fr
        @media (width > 500px)
            grid-template-columns: repeat(4, 1fr)
            & > *
                grid-column: span 2
                &:nth-child(2n+1):last-child
                    grid-column: 2 / span 2
