@import '../../Variables';

.black-border {
    border-color: var(--bs-dark);
    border: 2px;
    border-style: solid;
    font-weight: bold;
    padding: 0.7rem;
    justify-content: space-between;
    margin: 0;
    width: 100%;
}

.xyu{
    &-image {
        padding: 0;
        width: 100%;
        height: 100%;
        overflow: hidden;
        margin: 1.2rem 0 0 0;
        object-fit: cover;
        object-position: center;
    }
    &-container {
        padding: 0;
        @media (max-width: $mid-bp) {
            & > .row {
                flex-direction: column;
                & > * {
                    width: 100%;
                }
                & > :first-child {
                    aspect-ratio: unset;
                }
            }
        }
    }    
}

.col {
    &-image {
        padding: 1.3939rem 0 0 3vw;
        overflow: hidden;
        margin-bottom: 2.8939rem;
        width: 50%;
        @media (max-width: $mid-bp) {
            padding-left: 0;
        }
    }
    &-xyu-text {
        flex: 0 0 auto;
        width: 54.166666%;
    }
}