
.navbar-container {
    padding: 0;
    margin: 10px;
    width: 100%;
    max-width: none;
    .header__navbar {
        font-size: calc(20px + (100vw - 600px)/ 13);
        grid-template-columns: repeat(4, 1fr) {
            font-size: 20px;
        }
        @media (width > 900px) {
            font-size: calc(20px + (100vw - 900px) / 13);
        }
        @media (width > 1080px) {
            font-size: 33.84px;
        }
        @media (width < 475px) {
            display: none;
        }

    }
    .header__image {
        width: calc(var(--navbar-height) - 36px);
        height: calc(var(--navbar-height) - 36px);
    }
}