@import '../../Variables';

.modal-padding {
    padding: 0 1rem 1rem 1rem;
}

.text-col {
    width: 50%;
    padding: 0 1em;
    height: fit-content;
    @media (min-width: #{$mid-bp + 1}) {
        aspect-ratio: 1/1;
        padding: 0;
    }
}

.title {
    font-size: var(--title-font-size);
    font-weight: bold;
    line-height: 1.1;
    text-transform: uppercase;
    padding: 0.5rem 0;
    text-align: justify;
    margin: 0;
    width: fit-content;
}

.fit {
    padding: 1.3939rem;
    width: fit-content;
    height: fit-content;
}
