@import '../Variables'

.release-container 
    width: 100%
    height: 100%
    padding: 0
    margin: auto 0
    &-image
        height: 100% 
        width: 100%
        object-fit: cover 
        padding: 0.5rem 0
    &-title
        & > p 
            width: fit-content
            text-align: left 
            line-height: 1.1
    &-arrow
        width: fit-content
        height: fit-content
        border: none
        background: transparent
        line-height: 0.7
        font-weight: normal
        padding: 0.5em
        font-size: 1.5rem
        margin: 0 0 auto auto
        cursor: pointer
        color: $primary
        &--secondary
            @extend .release-container-arrow
            color: $secondary
    