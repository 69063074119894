@import '../Variables';
.album{
    &__hero {
        padding: 2em 0;
        display: flex;
        gap: 2em;
        &--image-container {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            img {
                width: 100%;
                height: 100%;
            }
        }
        &--side {
            padding: 0 3em;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            flex-shrink: 0;
        }
        &--counter {
            font-weight: bold;
            text-transform: uppercase;
        }
    }
    &__song {
        &--container {
            border: none;
            background: transparent;
            line-height: 0.7;
            font-weight: normal;
            font-size: 1.5rem;
            margin: 0em 0.66em auto auto;
            width: 100%;
            cursor: pointer;
            color: $primary;
            display: flex;
            justify-content: space-between;
            align-items: center;
            & + hr {
                height: 1px;
                margin: 0;
            }
            & > p {
                height: min-content;
                padding: 0;
                margin: 0;
                text-transform: uppercase;
                font-weight: bold;

            }
        }
        &-list {
            margin-bottom: var(--padding-width);
        }
    }
    &__container {
        & > hr:last-of-type {
            margin-bottom: 0;
        }
    }
}