@import '../Variables';
.song{
    &__hero {
        padding: 2em 0;
        display: flex;
        gap: 2em;
        &--image-container {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            min-width: 35%;
            &.album__cover {
                min-width: 25%;
                + .song__hero--side {
                    max-width: 75%;
                }
            }
            img {
                width: 100%;
                height: auto;
            }
        }
        &--side {
            padding: 0 3em;
            display: flex;
            flex-direction: column;
            justify-content: center;
            flex-shrink: 0;
            max-width: 65%;
            .extra {
                font-weight: 100;
            }
            & > * {
                max-width: 100%;
                &:is(p):last-of-type {
                    margin-bottom: 0.5em;
                }
            }
            & p + p {
                margin-bottom: 0;
            }
        }
        &--details {
            display: flex;
            flex-direction: column;
            gap: 1em;
            & > div {
                display: flex;
                gap: 1.5em;
                & > p {
                    text-transform: uppercase;
                    margin: 0;
                }
            }
        }
    }
    &__container {
        & > hr:last-of-type {
            margin-bottom: 0;
        }
        hr {
            height: 2px;
        }
    }
    &__text > div {
        display: grid;
        font-size: 4vw;
        @media (min-width: 576px) {
            font-size: inherit;
            &.grid-col-2 {
                grid-template-columns: 1fr 1fr;
            }
        }
    }
}
.section__header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    .extra-button {
        margin-right: 2em;
    }
}