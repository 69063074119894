@import '../Variables';

.link_grid {
    width: 100%;
    padding: 1.6em;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    @media (max-width: 624px) {
        grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
        .icons_label {
            display: none;
        }
    }
    row-gap: 0.2em;
    div, a {
        width: max-content;
    }
}
.row-legal {
    gap: 1em;
    .col:first-child {
        width: fit-content;
        @media (max-width: $sm-bp) {
            width: 100%;
            flex-basis: 100%;
            flex-shrink: 0;
            display: flex;
            justify-content: center;
        }
        p {
            text-align: left;
            margin: 0;
        }
    }
    .col:last-child {
        display: flex;
        height: fit-content;
        justify-content: flex-start;
        @media (max-width: $sm-bp) {
            justify-content: center;
        }
    }
}
.icons_label {
    padding: 0 0.6em;
    text-transform: uppercase;
}

.footer-image-col {
    width: 100%;
    @media(max-width: $sm-bp) {
        width: 100%;
        display: flex;
        justify-content: center;
        img {
            padding-right: 0 !important;
        }
    }
}

.bounded_container {
    padding: 1em;
    max-width: var(--max-content-width);

}

.invisible-row {
    color: var(--bs-dark);
}