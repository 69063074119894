@import '../Variables';

.withFullscreenImage {
    background-repeat: no-repeat;
    background-size: cover;
    max-height: calc(100vh - var(--navbar-height, 0));
    max-height: calc(100svh - var(--navbar-height, 0));
    max-width: 100vw;
    margin: 0 auto;
    aspect-ratio: 16/9;
    box-sizing: border-box;
    position: relative;
    &.primary {
        color: $primary;
    }
        
    &.secondary {
        color: $secondary;
    }
        
    &__content {
        box-sizing: content-box;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 5% 10%;
        @media (min-width: 651px) {
            position: absolute;
            width: 70%;
            top: 20%;
            bottom: 20%;
            left: 0;
            right: 0;
            margin: 0 auto;
            padding: 0 10%;
        }
        max-width: var(--max-content-width);
        &.album {
            height: 70%;
        }
        
    }
        
}