@import '../../Variables';

.modal-90w {
    width: 90vw;
    max-width: 90vw;
}

:target {
    scroll-margin-top: 96px;
}

.side-image {
    width: 50%;
    overflow: hidden;
    padding: 0;
    aspect-ratio: 1/1;
    background-size: cover;
    background-position: center;
}

.image-and-text-row {
    max-width: var(--max-content-width);
    margin: 0;
    &.reverse {
        flex-direction: row-reverse;
    }
    @media (max-width: $mid-bp) {
        &, &.reverse {
            flex-direction: column-reverse;
            & > * {
                width: 100% !important;
            }
        }
    }

}