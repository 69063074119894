@import '../../Variables';

.music-container {
    padding: 1.5rem 0;
}
.single-container {
    & > :last-child {
        height: 100%;
        width: fit-content;
        padding-left: 1.5rem;
    }
    @media (max-width: $mid-bp) {
        flex-direction: column;
        & > * {
            width: 100% !important;
        }
        & > :last-child {
            display: flex;
            gap: 2em;
            padding-left: 0;
            & > * {
                width: 100%;
            }
        }
    }
}

